<template>
  <div class="inspection-records-bar">
    <div class="inspection-records-title">
      <div class="title">{{$t('inspection.record')}}</div>
      <div class="export-records" @click="exportRecords">{{$t('inspection.exportRecords')}}</div>
    </div>
    <div class="inspection-records-search">
      <!-- <el-select
        class="uav-select"
        clearable
        v-model="form.inspectionType"
        @change="searchHandle"
        style="width: 145px"
        :placeholder="$t('allType')"
        size="small"
      >
        <el-option
          v-for="item in inspectionTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select> -->
      <el-input
        class="uav-input"
        size="small"
        @change="searchHandle"
        style="width: 150px;"
        v-model="form.name"
        :placeholder="$t('inspection.name')"
      >
        <template slot="suffix">
          <div class="search-icon-container">
            <i class="el-icon-search" @click="searchHandle"></i>
          </div>
        </template>
      </el-input>
      <el-select
        class="uav-select"
        clearable
        v-model="form.projectId"
        @change="searchHandle"
        style="width: 145px; margin-left: 10px;"
        :placeholder="$t('inspection.project')"
        size="small">
        <el-option
          v-for="item in projectList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <el-select
        class="uav-select"
        clearable
        :disabled="!projectId"
        v-model="form.taskId"
        @change="searchHandle"
        style="width: 150px; margin-left: 10px"
        :placeholder="$t('allTasks')"
        size="small"
      >
        <el-option
          v-for="item in taskList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <div class="margin-separate"></div>
      <el-date-picker
        size="small"
        v-model="form.time"
        style="width: 305px"
        class="uav-date-range"
        type="datetimerange"
        range-separator="至"
        :start-placeholder="$t('startTime')"
        :end-placeholder="$t('endTime')"
        :default-time="['00:00:00','23:59:59']"
        @change="searchHandle"
        value-format="yyyy-MM-dd HH:mm:ss"
        >
      </el-date-picker>
    </div>
    <div class="record-item-box"
      v-loading="loading"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
      <div
        class="record-item"
        v-for="item in inspectionList"
        :key="item.id"
        :class="selectInspection.id === item.id? 'record-item-active': ''"
        @mouseleave="leaveRecord"
        @click="chooseRecord(item)">
        <div class="record-title">
          <el-tooltip :content="item.name" placement="top">
              <div class="record-describe-mane">{{item.name}}</div>
          </el-tooltip>
          <span class="control-icon">
            <i
                @click.stop="flyTo(item)"
                v-if="item.id===selectInspection.id"
                style="margin-right: 4px"
                class="el-icon-aim"></i>
            <i
                @click.stop="deleteInspection(item)"
                v-if="item.id===selectInspection.id"
                :class="deleting? 'bgc-red': ''"
                style="margin-right: 4px"
                class="el-icon-delete"></i>
          </span>
        </div>
        <div class="record-task">{{ $t(`inspection.${item.inspectionType}`) }}</div>
        <el-tooltip :content="`${item.projectName} -- ${item.taskName}`" placement="right">
            <div class="record-describe">{{ item.projectName }} -- {{item.taskName}}</div>
        </el-tooltip>
        <div class="record-time">{{ item.startTime }} -- {{ item.endTime }}</div>
      </div>
    </div>
    <div class="left-table-pagination-line">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="size"
            :pager-count="3"
            size="small"
            background
            layout="total, prev, pager, next"
            :total="total"/>
    </div>
    <!-- 导出记录弹窗 -->
    <el-dialog class="exportDialog" title="导出巡检报告" :visible.sync="exportDialog" width="450px"
      :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" :before-close="handleCloseExport">
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
          <el-form-item label="报告日期：" prop="time">
            <el-date-picker type="date" size="small" placeholder="选择日期" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" v-model="ruleForm.time" clearable style="width: 100%;" />
          </el-form-item>
          <el-form-item label="巡检项目：" prop="inspection">
            <el-select v-model="ruleForm.inspection" size="small" clearable
              placeholder="请选择巡检项目" style="width: 100%;">
              <el-option v-for="(item, index) in projectList" :label="item.label"
                :value="item.value" :key="index + item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCloseExport">取 消</el-button>
        <el-button size="small" type="primary" @click="submitExport">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from "vuex";
  import { getProjectList } from '@/api/oil-gas'
  import { getList } from '@/api/phugiacloud/projectTask'
  import {
    getInspectionList,
    removeInspectionrecords,
    exportInspectionrecords } from '@/api/oil-gas/inspectionRecords/index'

  export default {
    name: "operationBar",
    data() {
      return {
        loading: false,
        size: 5,
        page: 1,
        total: 0,
        form: {
          inspectionType: '',
          name: '',
          projectId: '',
          taskId: '',
          time: []
        },
        inspectionTypeList: [
          {
            label: '油井',
            value: 'oilwell'
          },
          {
            label: '管线',
            value: 'oilline'
          }
        ],
        projectList: [],
        taskList: [],
        deleting: false,
        exportDialog: false,
        ruleForm: {
          time: '',
          inspection: ''
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        rules: {
          time: [
            {required: true, message: '请选择日期', trigger: 'change'}
          ],
          inspection: [
            {required: true, message: '请选择巡检项目', trigger: 'change'}
          ]
        }
      }
    },
    computed: {
      ...mapGetters([
        'selectInspection',
        'inspectionList'
      ]),
      projectId() {
        return this.form.projectId
      }
    },
    watch: {
      projectId(now) {
        if (now) {
          this.taskList = []
          this.getTaskList()
        } else {
          this.taskList = []
        }
        this.form.taskId = ''
      }
    },
    mounted() {
      this.getProjectList()
      this.getRecordList()
    },
    methods: {
      ...mapMutations([
        'SET_SELECT_INSPECTION',
        'SET_INSPECTION_LIST'
      ]),

      getProjectList() {
        getProjectList({
          current: 1,
          size: 9999
        }).then(res=>{
          if (res.data.code === 200) {
            this.projectList = res.data.data.records.map(item=>{
              return {
                value: item.id,
                label: item.projectName
              }
            })
          }
        })
      },

      getTaskList() {
        console.log(this.projectId);
        if (this.projectId) {
          getList(1, 9999, {
            projectId: this.projectId,
            taskBackgroundRequest: true
          }).then(res=>{
            if (res.data.code === 200) {
              this.taskList = res.data.data.records.map(item=>{
                return {
                  value: item.id,
                  label: item.taskName
                }
              })
            }
          })
        }
      },

      searchHandle() {
        this.getRecordList()
      },

      chooseRecord(node) {
        this.SET_SELECT_INSPECTION(node)
      },

      handleSizeChange(v) {
          this.size = v
          this.page = 1
          this.getRecordList()
      },

      handleCurrentChange(v) {
          this.page = v
          this.getRecordList()
      },

      getRecordList() {
        this.loading = true
        let params = {}
        let form = JSON.parse(JSON.stringify(this.form))
        for (var key in form) {
          if (key === 'time') {
            if ( form[key] && Array.isArray(form[key]) && form[key].length === 2 ) {
              params.startTime = form[key][0]
              params.endTime = form[key][1]
            }
            continue
          }
          if (form[key]) {
            params[key] = form[key]
            continue
          }
        }
        params.current = this.page
        params.size = this.size
        params.status = 2
        getInspectionList(params).then(res=>{
          if (res.data.code === 200) {
            this.SET_SELECT_INSPECTION({})
            let data = res.data.data.records
            this.total = res.data.data.total
            this.SET_INSPECTION_LIST(data)
          }
        }).finally(()=>{
          this.loading = false
        })
      },

      getInspectionTypeName(type) {
        for (let i = 0; i < this.inspectionTypeList.length; i++) {
          if (type === this.inspectionTypeList[i].value) {
            return this.inspectionTypeList[i].label
          }
        }
        return ''
      },

      deleteInspection(node) {
        if (!this.deleting) {
          this.deleting = true
          return
        }
        this.loading = true
        removeInspectionrecords({
          ids: node.id
        }).then(res=>{
          if (res.data.code == 200) {
            this.page = 1
            this.getRecordList()
            this.$message.success(this.$t('operationSuccessful'))
          } else {
            this.loading = false
          }
        }).catch(()=>{
          this.loading = false
        }).finally(()=>{
          this.deleting = false
        })
      },

      leaveRecord(){
        this.deleting = false
      },

      flyTo(node) {
        this.$EventBus.$emit('Inspection-record-flyto', node.inspectionId)
      },

      exportRecords() {
        this.ruleForm = {
          time: '',
          inspection: ''
        };
        this.exportDialog = true;
      },
      handleCloseExport() {
        this.exportDialog = false;
        this.$refs['ruleForm'].resetFields();
        this.ruleForm = {
          time: '',
          inspection: ''
        };
      },
      submitExport() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.downLoadRecordsFile();
          }
        });
      },
      downLoadRecordsFile() {
        const loading = this.$loading({
          lock: true,
          text: '正在导出记录，请稍候！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        exportInspectionrecords({flyDate: this.ruleForm.time, projectId: this.ruleForm.inspection}).then(res => {
          let index = res.headers["content-disposition"].indexOf("=") + 1;
          let fileName = decodeURIComponent(
            res.headers["content-disposition"].substr(index)
          );
          const blob = res.data;
          let link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.handleCloseExport();
        }).finally(() => {
          loading && loading.close();
        });
      }
    },
    beforeDestroy() {
      this.SET_SELECT_INSPECTION({})
    }
  };
</script>
<style lang="scss">
.inspection-records-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 346px;
  left: 2px;
  top: 30px;
  background-color: rgba(30, 34, 42, 1);
  .inspection-records-title {
    padding: 17px 22px 12px 22px;
    font-size: 14px;
    line-height: 20px;
    font-family: Microsoft Yahei UI;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #030303;
    display: flex;
    align-items: center;
    .title {
      flex: 1;
    }
    .export-records {
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }
  }
  .inspection-records-search {
    padding: 12px 18px 12px 22px;
    border-bottom: 1px solid #030303;
  }
  .uav-select {
    .el-input input {
      height: 30px;
    }
  }
  .uav-date-range {
    background-color: #030303;
    border-color: #444;
    .el-input__icon{
      display: none;
    }
    .el-range-input {
      background-color: transparent;
      color: #ffffff;
      width: 45%;
    }
  }
  input::placeholder {
    color: #444;
  }
  .search-icon-container {
    line-height: 24px;
    font-size: 24px;
    cursor: pointer;
    .el-icon-search{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .margin-separate {
    margin: 20px 0px;
  }
  .record-item-box{
    flex-grow: 1;
    overflow: auto;
    .record-item {
        cursor: pointer;
        height: 126px;
        box-sizing: border-box;
        border-bottom: 1px solid #030303;
        padding: 16px 10px;
        color: rgba(153, 153, 153, 0.79);
        .record-title {
            font-size: 14px;
            font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
        }
        .record-task {
            height: 15px;
            margin-top: 16px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            color: rgba(153,153,153,0.79);
            line-height: 15px;
        }
        .record-describe-mane {
            color: #FFFFFF;
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-describe {
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-time {
            height: 15px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-show {
            color: #3573FF;
        }
    }
  }
  .record-item-active {
      background-color: #3573FF33;
  }
  .bgc-red {
    background-color: #f00;
  }

  .exportDialog {
    .el-dialog {
      background-color: rgba(30, 34, 42, 1);
      .el-dialog__header {
        .el-dialog__title {
          font-size: 16px;
          color: #FFFFFF;
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #ffffff;
        }
      }
      .el-dialog__body {
        padding: 15px 20px;
        .content {
          .el-form-item__label {
            color: #ffffff;
          }
          .el-input__inner {
            font-size: 14px;
            color: #ffffff;
            background-color: transparent;
            &::placeholder {
              color:  rgba(153, 153, 153, 0.79);
            }
          }
        }
      }
    }
  }
}
</style>
